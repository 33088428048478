import React, { memo, useContext, useEffect, useState } from 'react';
import './SayHelloPopup.scss';
import { Button, Checkbox, Col, message, Modal, Popover, Row, Steps } from 'antd';
import { PersonProp } from '../PeopleTab/PeopleTabSlice';
import TypeMessageContainer from './TypeMessageContainer';
import YourSelfContainer from './YourSelfContainer';
import { FiSend } from '@react-icons/all-files/fi/FiSend';
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import { MdArrowBackIos } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterProps } from '../../../Models/RegisterProps';

import {
  clearRegisterProspects,
  registerProspectSelector,
  registerProspectUser,
  setRegisterSuccessAfterOTPValidate,
} from '../../RegisterProspect/RegisterProspectSlice';
import useProfileImage from '../../../../assets/images/default_user_image.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks';
import { getUniversityResourceSelector } from '../../../../MainScreen/MainScreenSlice';
import { IsUserLoggedContext } from '../../../../App';
import {
  clearSendChatMessage,
  sendChatMessage,
  sendChatMessageSelector,
  validateMessageRequest,
} from '../../Chat/ChatArea/SendMessage/SendMessageSlice';
import MobileVerification from './MobileVerification';
import { clearValidateOTP, validateOTP, validateOTPSelector } from './ValidateOTP/ValidateOTPSlice';
import { useSearchParams } from 'react-router-dom';
import { getIpDetailsSelector } from '../../../../api/IpDataSlice';
import { resetSayHelloPopupState } from './SayHelloPopupSlice';
import { isMobile } from 'react-device-detect';

const steps = [
  {
    title: 'Your Message',
    content: 'First-content',
  },
  {
    title: 'Your Information',
    content: 'Second-content',
  },
  {
    title: 'Receive an Answer',
    content: 'Second-content',
  },
];

const { Step } = Steps;

type SayHelloPopupProps = {
  buddies: any;
  visible: boolean;
  setVisible: (isVisible: boolean, isSuccess: boolean) => void;
  id: number;
  handleCallback?: (data: any) => void;
  structureProgrammes?: any;
  countryCode?: any;
  enableWordPress?: boolean;
};

function SayHelloPopup({
  buddies,
  setVisible,
  visible,
  id,
  handleCallback,
  structureProgrammes,
  countryCode,
  enableWordPress,
}: SayHelloPopupProps) {
  const [searchParams] = useSearchParams();

  const refKey = searchParams.get('refKey');

  const { payload } = useAppSelector(getUniversityResourceSelector);
  const [buddyProfile, setBuddyProfile] = useState<PersonProp>();
  const [current, setCurrent] = React.useState(0);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const [messageContent, setMessageContent] = useState<string>('');
  const [applicationNumber, setApplicationNumber] = useState<string>('');
  const [selectedProgrammes, setSelectedProgrammes] = useState<any>([]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isOTPValidated, setIsOTPValidated] = useState<boolean | undefined>(undefined);
  const universityConfigResponse = useSelector(getUniversityResourceSelector);
  const { payload: ipPayload } = useSelector(getIpDetailsSelector);
  const { status, isFetching, sessionDetails, error } = useSelector(registerProspectSelector);

  const [isRecaptchaValidate, setIsRecaptchaValidate] = useState<boolean>(false);

  const validateOTPResponse = useSelector(validateOTPSelector);

  const {
    status: sendMessageStatus,
    isFetching: isSendingMessage,
    error: sendMessageError,
    messageValidationStatus,
    isFetchingMessageValidation,
    messageValidationResponse,
    messageValidationError,
  } = useSelector(sendChatMessageSelector);
  const [, SetUserTermsVisiblity] = useState(false);
  const [reqRequest, setReqRequest] = useState<RegisterProps>({
    id: 0,
    message: '',
    channels: ['EMAIL'],
    email: '',
    name: '',
    phone: '',
    whatsappNumber: '',
    ip: '',
    applicationNumber: '',
    courseId: [],
    isWhatsAppNumber: false,
    countryCode: '',
    dialCode: '',
  });

  const [backedFrom, setBackedFrom] = useState(0);
  const dispatch = useDispatch();

  const themeColor = payload?.theme?.themeColor;

  function onSendRequest(reqRequest: RegisterProps) {
    // console.log("Sending Course IDs:", reqRequest.courseId);
    dispatch(
      registerProspectUser({
        ...reqRequest,
        message: messageContent,
        id: id,
        ip: ipPayload?.ip ? ipPayload?.ip : '',
        applicationNumber,
        courseId: reqRequest.courseId,
        countryCode: countryCode,
      })
    );
    if (handleCallback) {
      handleCallback(reqRequest);
    }
  }

  const { t } = useTranslation();

  const isLoggedIn = useContext(IsUserLoggedContext);

  useEffect(() => {
    setCurrent(0);
    // message.error(error?.message)
  }, [error]);

  useEffect(() => {
    if (id) {
      setReqRequest({ ...reqRequest, id: id });
      setBuddyProfile(buddies);
    }
  }, [id]);

  useEffect(() => {
    if (!!refKey) {
      setReqRequest((prevState) => ({ ...prevState, refKey }));
      localStorage.setItem('refKey', refKey);
    }
  }, [refKey]);

  useEffect(() => {
    if (status === 'SUCCESS' && !!sessionDetails && isOTPValidated) {
      dispatch(clearRegisterProspects());
    } else if (status === 'SUCCESS' && !!sessionDetails) {
      setVisible(false, true);
      dispatch(clearRegisterProspects());
      dispatch(clearSendChatMessage());
    } else if (status === 'FAILED') {
      message.error(error?.message);
      dispatch(clearRegisterProspects());
    }
  }, [status, isOTPValidated]);

  useEffect(() => {
    if (sendMessageStatus === 'SUCCESS') {
      setVisible(false, true);
      dispatch(clearSendChatMessage());
    } else if (sendMessageStatus === 'FAILED') {
      message.error(sendMessageError);
    }
  }, [sendMessageStatus]);

  useEffect(() => {
    if (messageContent?.length > 0 && (agreedToTerms || isLoggedIn)) {
      if (payload?.univeristyConfig?.recaptureEnabled && !isRecaptchaValidate) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    } else {
      setDisableButton(true);
    }
  }, [messageContent, agreedToTerms, isRecaptchaValidate]);

  useEffect(() => {
    if (messageValidationStatus === 'SUCCESS') {
      // console.log("=== messageValidation ===");
      setCurrent(current + 1);
    } else if (messageValidationStatus === 'FAILED') {
      message.error(messageValidationError);
    }
  }, [messageValidationStatus]);

  const handleCancel = () => {
    setVisible(false, false);
    setCurrent(0);
  };

  const next = (isLogged: boolean) => {
    if (!isLogged) {
      dispatch(
        validateMessageRequest({
          message: messageContent,
        })
      );
      // setCurrent(current + 1);
      SetUserTermsVisiblity(true);
    } else {
      if (backedFrom === 2) {
        setCurrent(current + 1);
        SetUserTermsVisiblity(true);
      } else {
        setCurrent(0);
        dispatch(
          sendChatMessage({
            buddyID: id,
            message: messageContent,
          })
        );
      }
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    setBackedFrom(current);
    SetUserTermsVisiblity(false);
  };

  useEffect(() => {
    if (visible) {
      setCurrent(0);
    }
  }, [visible]);

  useEffect(() => {
    return () => {
      dispatch(resetSayHelloPopupState());
      setCurrent(0);
    };
  }, [dispatch]);

  function stepMaker(stepNo: number) {
    switch (stepNo) {
      case 0:
        return (
          <TypeMessageContainer
            messageState={messageContent}
            setMessageState={setMessageContent}
            structureProgrammes={structureProgrammes}
            setApplicationNumber={setApplicationNumber}
            setSelectedProgrammes={setSelectedProgrammes}
            isLoggedIn={isLoggedIn}
            enableWordPress={enableWordPress}
          />
        );
      case 1:
        return (
          <YourSelfContainer
            onNext={(regProps) => {
              if (
                !!universityConfigResponse &&
                !!universityConfigResponse.payload &&
                universityConfigResponse.payload.univeristyConfig.prospectVerificationEnabled
              ) {
                onSendRequest({ ...regProps, id: id });
                setReqRequest({ id: id, ...regProps });
                next(isLoggedIn);
              } else {
                onSendRequest({ ...regProps, id: id });
              }
            }}
            messageContent={messageContent}
            initValue={reqRequest}
            agreedToTerms={agreedToTerms}
            setAgreedToTerms={(checked) => setAgreedToTerms(checked)}
            universityResourceState={payload}
            countryCode={countryCode}
            setIsRecaptchaValidate={setIsRecaptchaValidate}
          />
        );
      case 2:
        return !!sessionDetails ? (
          <MobileVerification
            onSubmit={(otp) => {
              doValidateOTP(otp);
            }}
            otpValidationState={isOTPValidated}
            phoneNumber={reqRequest.phone}
            email={reqRequest.email}
            pid={sessionDetails.prospectId}
          />
        ) : (
          <></>
        );
    }
  }

  function doValidateOTP(otp: string) {
    if (!!sessionDetails) {
      dispatch(validateOTP({ code: otp, pid: sessionDetails.prospectId }));
    }
  }

  useEffect(() => {
    if (validateOTPResponse.response === 'Success') {
      dispatch(setRegisterSuccessAfterOTPValidate());
      setIsOTPValidated(true);
      dispatch(clearValidateOTP());
      setVisible(false, true);
    } else if (validateOTPResponse.status === 'SUCCESS') {
      setIsOTPValidated(false);
    }
  }, [validateOTPResponse]);

  useEffect(() => {
    if (!visible) {
      setMessageContent('');
    }
  }, [visible]);

  useEffect(() => {
    if (messageContent) {
      setReqRequest({ ...reqRequest, message: messageContent });
    }
  }, [messageContent]);

  // console.log('=== isRecaptchaValidate ===', isRecaptchaValidate);

  return (
    <div key={id}>
      <Modal
        className="sayHelloPopupLayout"
        style={{ top: 20 }}
        visible={visible}
        title={
          <div className={enableWordPress ? 'sayHelloPopupTitle-iFrame' : 'sayHelloPopupTitle'}>
            <div className={enableWordPress ? 'sayHelloPopupImg-iFrame' : 'sayHelloPopupImg'}>
              <img
                className="sayHelloPopupImgIn sayHelloPopupImgMobileIn"
                src={buddyProfile?.profile?.smallProfileUrl || useProfileImage}
                alt="empty"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = useProfileImage;
                }}
              />
            </div>
            <div className="sayHelloPopupTitleText">
              <div className="sayHelloPopupTitleTextIn">
                {current === steps.length - 1
                  ? t('dashboard.SayHelloPopup.sayHelloPopupTitleTextLast')
                  : t('dashboard.SayHelloPopup.sayHelloPopupTitleText')}
                {/* {t("dashboard.SayHelloPopup.sayHelloPopupTitleText")}{" "} */}
              </div>
            </div>
          </div>
        }
        onCancel={handleCancel}
        footer={[
          <div className="steps-action">
            {current === 0 && (
              <>
                <Row align="middle" style={{ justifyContent: 'flex-end' }}>
                  <Col lg={9} xs={24}>
                    <div className="modalStepsActions">
                      <Button
                        className="btnClose btn-lg"
                        type="primary"
                        ghost
                        onClick={() => handleCancel()}
                        style={{
                          color: `${themeColor}`,
                          borderColor: `${themeColor}`,
                        }}
                      >
                        {t('dashboard.SayHelloPopup.btnClose')}
                      </Button>

                      {!isLoggedIn ? (
                        <Button
                          className="btnContinue btn-lg"
                          type="primary"
                          disabled={!(messageContent.length > 0)}
                          onClick={() => next(false)}
                          style={{
                            backgroundColor: `${themeColor}`,
                            borderColor: `${themeColor}`,
                          }}
                        >
                          <div className="btnContinueText"> {t('dashboard.SayHelloPopup.btnContinue')}</div>
                          <IoIosArrowForward />
                        </Button>
                      ) : (
                        <Button
                          className="btnContinue btn-lg"
                          loading={isSendingMessage}
                          type="primary"
                          disabled={disableButton}
                          onClick={() => next(isLoggedIn)}
                          icon={<FiSend />}
                          style={{
                            backgroundColor: `${themeColor}`,
                            borderColor: `${themeColor}`,
                          }}
                        >
                          <span className="btnContinueText"> {t('dashboard.SayHelloPopup.btnSendMessage')} </span>
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            )}

            <span className={current === 2 ? 'secondStepFooterEnd' : 'secondStepFooter'}>
              {current > 0 && current < 2 && (
                <Button
                  className="sayHelloPopuBackButton btn-lg"
                  onClick={() => prev()}
                  style={{
                    color: `${themeColor}`,
                    borderColor: `${themeColor}`,
                  }}
                >
                  <MdArrowBackIos />
                  {t('dashboard.SayHelloPopup.btnBackButton')}{' '}
                </Button>
              )}
              {current === 1 &&
                !!universityConfigResponse &&
                !!universityConfigResponse.payload &&
                universityConfigResponse.payload.univeristyConfig.prospectVerificationEnabled && (
                  <Button
                    className="btnContinue btn-lg"
                    type="primary"
                    loading={isFetching}
                    disabled={disableButton}
                    htmlType={'submit'}
                    form="addSummeryForm"
                    style={{
                      backgroundColor: `${themeColor}`,
                      borderColor: `${themeColor}`,
                    }}
                  >
                    <div className="btnContinueText"> {t('dashboard.SayHelloPopup.btnContinue')}</div>
                    <IoIosArrowForward />
                  </Button>
                )}
              {current === 1 &&
                !!universityConfigResponse &&
                !!universityConfigResponse.payload &&
                !universityConfigResponse.payload.univeristyConfig.prospectVerificationEnabled && (
                  <Button
                    className="btnSendMessage btn-lg"
                    loading={isFetching}
                    type="primary"
                    disabled={disableButton}
                    htmlType={'submit'}
                    form="addSummeryForm"
                    icon={<FiSend />}
                    style={{
                      backgroundColor: `${themeColor}`,
                      borderColor: `${themeColor}`,
                    }}
                  >
                    <span className="sendMessageButtonText"> {t('dashboard.SayHelloPopup.btnSendMessage')} </span>
                  </Button>
                )}
              {current === steps.length - 1 &&
                !!universityConfigResponse &&
                !!universityConfigResponse.payload &&
                universityConfigResponse.payload.univeristyConfig.prospectVerificationEnabled && (
                  <Button
                    className="btnSendMessage btn-lg"
                    loading={isFetching}
                    type="primary"
                    htmlType={'submit'}
                    form="otpForm"
                    icon={<FiSend />}
                    style={{
                      backgroundColor: `${themeColor}`,
                      borderColor: `${themeColor}`,
                    }}
                  >
                    <span className="sendMessageButtonText"> {t('dashboard.SayHelloPopup.btnSendMessage')} </span>
                  </Button>
                )}
            </span>
          </div>,
        ]}
        destroyOnClose={true}
      >
        {!isLoggedIn && (
          <>
            {current !== steps.length - 1 ? (
              <Steps
                current={current}
                className="stepperMargin"
                type={isMobile ? 'default' : 'navigation'}
                responsive={false}
                labelPlacement={isMobile ? 'vertical' : 'horizontal'}
                size={isMobile ? 'small' : 'default'}
              >
                {status !== 'SUCCESS' && steps.map((item) => <Step key={item.title} title={item.title} />)}
              </Steps>
            ) : (
              <></>
            )}
          </>
        )}
        <div className="steps-content">{stepMaker(current)}</div>
      </Modal>
    </div>
  );
}

export default memo(SayHelloPopup);
